import styled, { css } from 'styled-components';

import { CommonLink } from '@/components/buttons-links/CommonLink';

import fontSmoothing from '@/styles/js/fonts/smoothing';
import typeStyle from '@/styles/js/fonts/typeStyles';
import { resetButtonStyles } from '@/styles/js/utils/interations';
import { notTouch } from '@/styles/js/utils/media';
import { em } from '@/styles/js/utils/units';

import { Icon as StyledIcon } from '../graphics/Icon.styles';

/**
 * Styled ActionRow
 * ---
 *
 * Props
 * - `reserve` - 100% width of parent
 * - `last` - For when the last item should have unique appearance
 */
export const StyledActionRow = styled(CommonLink)`
  ${resetButtonStyles}
  ${typeStyle('body-m', 'medium')}

  width: 100%;
  text-align: left;
  padding: ${em(16, 14)} 0 ${em(18, 14)};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.accent};
  border: 1px solid ${({ theme }) => theme.colors.rule};
  border-right: none;
  border-left: none;
  display: block;
  position: relative;

  & + & {
    border-top: none;
  }

  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.accentFaint};
  }

  ${notTouch} {
    &:hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  > ${StyledIcon}:first-child {
    position: absolute;
    top: 0;
    bottom: 0.2rem;
    margin: auto 0;
  }

  > ${StyledIcon}:first-child + span {
    padding-left: 3.5rem;
  }

  > ${StyledIcon}:last-child {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: ${em(14, 16)};
  }

  ${({ theme, reverse, last }) => css`
    ${reverse &&
    css`
      ${fontSmoothing}

      color: ${theme.colors.white};
      border-color: ${theme.colors.ruleReverse};

      ${notTouch} {
        &:hover {
          color: rgb(${theme.colors.white}, 0.7);
        }
      }
    `}
    ${last &&
    css`
      border-bottom: none;
      margin-bottom: ${em(16, 14)} * -1;
    `}
  `}
`;

/**
 * ActionRow
 * ---
 * Displays an action the user can take. Typically used
 * when there are several actions the user can choose
 * between, as this component is more subtle and space-efficient
 * than other types of buttons.
 *
 * Props
 * - `icon`
 *
 * Styled props
 * - `reserve` - 100% width of parent
 * - `last` - For when the last item should have unique appearance
 */
const ActionRow = ({ icon, ...other }) => (
  <StyledActionRow
    iconBefore={icon}
    iconAfter="arrow-small-right-24"
    {...other}
  />
);

export default ActionRow;
