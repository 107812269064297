import PropTypes from 'prop-types';
import { memo } from 'react';

import useMediaQuery from '@/hooks/useMediaQuery';

/**
 * Automatically displays `children` based on the viewport
 * size.
 *
 * `min` is the minimum viewport size where the content will display
 * `max` is the maximum viewport size where the content will display
 */
const Mq = ({ children, ...props }) => {
  const matches = useMediaQuery(props);

  if (typeof children === 'function') {
    return <>{children(matches)}</>;
  }

  if (!matches) {
    return null;
  }

  return <>{children}</>;
};

Mq.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
};

export default memo(Mq);
