import PropTypes from 'prop-types';
import { memo } from 'react';

import Icon from '@/components/graphics/Icon';

import { StyledNextLink } from '@/lib/next';

import * as Styled from './index.styles';

/**
 * The navigation bar that often sits at the top of modal views.
 */
const NavBar = ({
  back = '',
  title = '',
  backIcon = '',
  action = '',
  responsive = false,
  onBackClick,
  backTo,
  onCloseClick,
  closeTo,
  onActionClick,
}) => {
  const isFunc = (it) => typeof it === 'function';
  const shouldRenderAction = !!action && isFunc(onActionClick);
  const shouldRenderTitle = typeof title === 'string';
  const shouldRenderBack =
    back ||
    backIcon ||
    isFunc(onBackClick) ||
    backTo ||
    isFunc(onCloseClick) ||
    closeTo;

  const renderBackBtn = () => {
    let dynamicProps = {};

    // Set the click action
    if (isFunc(onBackClick)) dynamicProps.onClick = onBackClick;
    if (isFunc(onCloseClick)) dynamicProps.onClick = onCloseClick;

    // Set the `href` path
    if (backTo) dynamicProps.href = backTo;
    if (closeTo) dynamicProps.href = closeTo;

    // Extend "<Link />" instead of being a "span"
    if (backTo || closeTo) dynamicProps.as = StyledNextLink;

    // Set the icon
    let icon = 'arrow-left-24';
    if (closeTo || onCloseClick) icon = 'close-x-24';
    if (backIcon) icon = backIcon;

    return (
      <Styled.Back {...dynamicProps}>
        <Styled.BackIcon>
          <Icon type={icon} />
        </Styled.BackIcon>
        {!!back && <Styled.BackText>{back}</Styled.BackText>}
      </Styled.Back>
    );
  };

  return (
    <Styled.NavBar responsive={responsive}>
      {shouldRenderTitle && (
        <Styled.Title responsive={responsive}>{title}</Styled.Title>
      )}

      {shouldRenderBack && renderBackBtn()}

      {shouldRenderAction && (
        <Styled.Action responsive={responsive} onClick={onActionClick}>
          {action}
        </Styled.Action>
      )}
    </Styled.NavBar>
  );
};

NavBar.propTypes = {
  title: PropTypes.string,
  backIcon: PropTypes.string,
  onBackClick: PropTypes.func,
  back: PropTypes.string,
  backTo: PropTypes.string,
  onCloseClick: PropTypes.func,
  closeTo: PropTypes.string,
  action: PropTypes.string,
  onActionClick: PropTypes.func,
  responsive: PropTypes.bool,
};

export default memo(NavBar);
