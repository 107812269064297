import PropTypes from 'prop-types';
import { memo } from 'react';

import FormLabel from '@/components/forms/FormLabel';
import Text from '@/components/text-headings/Text';

import * as S from './FormGroup.styles';

/**
 * Combines a form label with a child input.
 */
function FormGroup({
  required = false,
  name,
  children,
  label,
  optional,
  description,
  errorMessage,
  large,
}) {
  return (
    <S.FormGroup name={name}>
      <FormLabel
        required={required}
        htmlFor={name}
        large={large}
        optional={optional}
      >
        {label}
      </FormLabel>
      {description && (
        <Text>
          <p>{description}</p>
        </Text>
      )}
      {children}
      {errorMessage && (
        <Text color="warning">
          <p>{errorMessage}</p>
        </Text>
      )}
    </S.FormGroup>
  );
}

FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  required: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorMessage: PropTypes.string,
  large: PropTypes.bool,
};

export default memo(FormGroup);
