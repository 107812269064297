import ua from 'ua-parser-js';

const { type: deviceType } = ua().device;

export function isMobile() {
  return deviceType === 'mobile';
}

export function extractUaFromHeaders(uaHeader) {
  return ua(uaHeader);
}
