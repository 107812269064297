import useMediaQuery from '@/hooks/useMediaQuery';

import * as Styled from './index.styles';

const isServer = typeof window === 'undefined';

export function StatusBarArea(props) {
  const desktop = useMediaQuery({
    min: 'medium',
  });

  if (desktop) return null;

  let hasStatusBar = false;

  if (!isServer) {
    hasStatusBar = !!window.statusBarHeight;
  }

  return <Styled.StatusBarArea {...props} $hasStatusBar={hasStatusBar} />;
}
