import styled from 'styled-components'; // Add /macro to the import

import { commonSpacingContent } from '@/styles/js/utils/spacing';
import mq from '@/styles/js/utils/mq';
import fluidSize from '@/styles/js/utils/fluidSize';

// FIXME Replace reference to .FormLabel--large when it's converted

export const FormGroup = styled.div`
  text-align: left;
  ${commonSpacingContent()};

  > fieldset {
    padding: 0;
    position: relative;
  }

  > .FormLabel--large + * {
    ${mq('medium')} {
      ${fluidSize('lay-m')}
    }
  }
`;
